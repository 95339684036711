import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './EventCalendar.css';
import { useNavigate } from 'react-router-dom';
const localizer = momentLocalizer(moment);

const EventCalendar = () => {
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({
    title: '',
    start: '',
    end: '',
    description: '',
  });
  const [showForm, setShowForm] = useState(false);
  const navigate = useNavigate();
  // Fetch events from backend
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch('https://hocompany.website/api/calendar.php');
        const formattedEvents = await response.json();
        setEvents(
          formattedEvents.map((event) => ({
            title: event.title,
            start: new Date(event.start), 
            end: new Date(event.end), 
            description: event.description,
          }))
        );
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };
    fetchEvents();
  }, []);
  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEvent((prev) => ({ ...prev, [name]: value }));
  };

  // Submit the new event
  const handleSubmit = async (e) => {
    e.preventDefault();

    const eventToSave = {
      ...newEvent,
      start: new Date(newEvent.start).toISOString(), 
      end: new Date(newEvent.end).toISOString(),
    };

    try {
      const response = await fetch('https://hocompany.website/api/calendar.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(eventToSave),
      });

      const result = await response.json();

      if (result.success) {
        setEvents([...events, { ...newEvent, start: new Date(newEvent.start), end: new Date(newEvent.end) }]);
        setNewEvent({ title: '', start: '', end: '', description: '' });
        setShowForm(false);
      } else {
        alert('Error adding event: ' + result.message);
      }
    } catch (error) {
      console.error('Error adding event:', error);
    }
  };
  const handleBackClick = () => {
    navigate('/');  
  };
  return (
    <div className="event-calendar-container">
      <h1>Event Calendar</h1>
      <button className="add-event-button" onClick={() => setShowForm(true)}>
        Add Event
      </button>
      <button className="back-button" onClick={handleBackClick}>
        Back
      </button>
      {showForm && (
        <div className="modal">
          <form className="event-form" onSubmit={handleSubmit}>
            <h2>Add Event</h2>
            <label>
              Event Title
              <input
                type="text"
                name="title"
                placeholder="Enter event title"
                value={newEvent.title}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Start Time
              <input
                type="datetime-local"
                name="start"
                value={newEvent.start}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              End Time
              <input
                type="datetime-local"
                name="end"
                value={newEvent.end}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Event Description
              <textarea
                name="description"
                placeholder="Add event description"
                value={newEvent.description}
                onChange={handleInputChange}
              ></textarea>
            </label>
            <button type="submit">Add Event</button>
            <button type="button" onClick={() => setShowForm(false)}>
              Cancel
            </button>
          </form>
        </div>
      )}

      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, marginTop: '20px' }}
        selectable
        views={['month', 'week', 'day']}
        defaultView="month"
      />
    </div>
  );
};

export default EventCalendar;