import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from './images/logo.png';
import './Header.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const [dropdowns, setDropdowns] = useState({ home: false, projects: false });

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
    setMenuOpen(false);
    setDropdowns({ home: false, projects: false });
  };

  const toggleDropdown = (dropdownName) => {
    setDropdowns((prevState) => ({
      ...prevState,
      [dropdownName]: !prevState[dropdownName],
    }));
  };

  return (
    <header className="header">
      {/* Logo Section */}
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>

      {/* Hamburger Menu */}
      <div className={`hamburger ${menuOpen ? "active" : ""}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>

      {/* Navigation Menu */}
      <ul className={`menu ${menuOpen ? "open" : ""}`}>
        {/* Home Dropdown */}
        <li className={`home ${dropdowns.home ? "open" : ""}`}>
          <span
            className={activeLink === "Home" ? "active" : ""}
            onClick={() => toggleDropdown("home")}
          >
            Home{" "}
            <span className={`arrow ${dropdowns.home ? "down" : "up"}`}>^</span>
          </span>
          {dropdowns.home && (
            <ul className="dropdown">
              <li>
                <Link
                  to="/"
                  className={activeLink === "HomePage" ? "active" : ""}
                  onClick={() => handleLinkClick("HomePage")}
                >
                  Main Home
                </Link>
              </li>
              <li>
                <Link
                  to="/features"
                  className={activeLink === "Features" ? "active" : ""}
                  onClick={() => handleLinkClick("Features")}
                >
                  Features
                </Link>
              </li>
              <li>
                <Link
                  to="/dashboard"
                  className={activeLink === "Dashboard" ? "active" : ""}
                  onClick={() => handleLinkClick("Dashboard")}
                >
                  Dashboard
                </Link>
              </li>
            </ul>
          )}
        </li>

        {/* Projects Dropdown */}
        <li className={`projects ${dropdowns.projects ? "open" : ""}`}>
          <span
            className={activeLink === "Projects" ? "active" : ""}
            onClick={() => toggleDropdown("projects")}
          >
            Projects{" "}
            <span className={`arrow ${dropdowns.projects ? "down" : "up"}`}>^</span>
          </span>
          {dropdowns.projects && (
            <ul className="dropdown">
              <li>
                <Link
                  to="/projects"
                  className={activeLink === "ProjectsPage" ? "active" : ""}
                  onClick={() => handleLinkClick("ProjectsPage")}
                >
                  Projects
                </Link>
              </li>
              <li>
                <Link
                  to="/buildcostpro"
                  className={activeLink === "/buildcostpro" ? "active" : ""}
                  onClick={() => handleLinkClick("/buildcostpro")}
                >
                  BuildCostPro
                </Link>
              </li>
              <li>
                <Link
                  to="/materials"
                  className={activeLink === "Materials" ? "active" : ""}
                  onClick={() => handleLinkClick("Materials")}
                >
                  Materials
                </Link>
              </li>
              <li>
                <Link
                  to="/calendar"
                  className={activeLink === "Calendar" ? "active" : ""}
                  onClick={() => handleLinkClick("Calendar")}
                >
                  Calendar
                </Link>
              </li>
              
            </ul>
          )}
        </li>

        {/* Other Links */}
        <li>
          <Link
            to="/jobs"
            className={activeLink === "Jobs" ? "active" : ""}
            onClick={() => handleLinkClick("Jobs")}
          >
            Jobs
          </Link>
        </li>
        <li>
          <Link
            to="/contact"
            className={activeLink === "Contact" ? "active" : ""}
            onClick={() => handleLinkClick("Contact")}
          >
            Contact
          </Link>
        </li>
        <li>
          <Link
            to="/reviews"
            className={activeLink === "Reviews" ? "active" : ""}
            onClick={() => handleLinkClick("Reviews")}
          >
            Reviews
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            className={activeLink === "About" ? "active" : ""}
            onClick={() => handleLinkClick("About")}
          >
            About
          </Link>
        </li>
      </ul>
    </header>
  );
};

export default Header;