import React, { useState, useEffect } from 'react';
import './Dashboard.css';


const Dashboard = () => {
  const [applications, setApplications] = useState([]);
  const [users, setUsers] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false); 
  const [visitors, setVisitors] = useState(0); 

  useEffect(() => {
    const savedUser = JSON.parse(localStorage.getItem('user')); 
    if (savedUser && savedUser.email === 'erahidaj@gmail.com') {
      setIsAdmin(true); 
    }
  }, []); 
  // Fetch applications for jobs
  useEffect(() => {
    fetch('https://hocompany.website/api/get-application.php')
      .then((response) => response.json())
      .then((data) => {
        if (data.applications) {
          setApplications(data.applications);
        }
      })
      .catch((error) => console.error('Error fetching applications:', error));
  }, []);

  useEffect(() => {
    fetch('https://hocompany.website/api/registeredUser.php')
      .then((response) => response.json())
      .then((data) => {
        if (data.total) {
          setUsers(parseInt(data.total, 10)); 
        }
      })
      .catch((error) => console.error('Error fetching users:', error));
  }, []);
  
  // Fetch ratings and comments
  useEffect(() => {
    fetch('https://hocompany.website/api/ratingsPage.php')
      .then((response) => response.json())
      .then((data) => {
        if (data.ratings) {
          setRatings(data.ratings);
        }
      })
      .catch((error) => console.error('Error fetching ratings:', error));
  }, []);
  useEffect(() => {
    fetch('https://hocompany.website/api/pageVisit.php') 
      .then((response) => response.json())
      .then((data) => {
        console.log('Fetching visitors count...');
        if (data.visitors) {
          setVisitors(data.visitors);
        }
      })
      .catch((error) => console.error('Error fetching visitors:', error));
  }, []);
  return (
    <div className="dashboard">
      <h1>DASHBOARD</h1>

      {/* Kontrolloni nëse përdoruesi është admin dhe shfaqni elementet vetëm për ta */}
      {isAdmin ? (
        <div className="stats">
          <div className="stat-card">
            <h2>Job Applicants</h2>
            <p>{applications && applications.length ? applications.length : 'No applications'}</p>
          </div>
          <div className="stat-card">
            <h2>Registered Users</h2>
            <p>{users > 0 ? users : 'No registered users'}</p>
          </div>
          <div className="stat-card">
            <h2>Ratings</h2>
            <p>{ratings.length}</p>
          </div>
          <div className="stat-card">
            <h2>Page Visitors</h2>
            <p>{visitors > 0 ? visitors : 'No visitors yet'}</p>
          </div>
        </div>
      ) : (
        <p style={{ color: 'red', textAlign: 'center', fontSize: '20px' }}>
        <i className="fa fa-ban" style={{ marginRight: '10px' }}></i>
        You do not have access to this dashboard
      </p>
      )}
    </div>
  );
};

export default Dashboard;